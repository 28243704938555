<template>
  <WidgetLeadsTable
    widget-classes="card-xxl-stretch mb-5 mb-xl-8"
  ></WidgetLeadsTable>
</template>

<script>
import WidgetLeadsTable from "@/components/widgets/tables/WidgetLeadsTable";

export default {
  name: "LeadsTable",
  components: {
    WidgetLeadsTable,
  },
}
</script>

<style scoped>

</style>