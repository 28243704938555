
import {computed, defineComponent, onMounted, ref} from "vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import moment from "moment/moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-widget-leads-table",
  components: {
    //
  },
  props: {
    widgetClasses: String,
    hideBtnNewMember: Boolean
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const checked = ref(false);
    const loader = ref<HTMLElement | null>(null);
    let isSearched = ref(false),
        loading = ref(false);


    onMounted(() => {
      selectedPartner.value = 'Choose a partner'
      store.commit(Mutations.SET_LEADS_LIST, null);
      isSearched.value = false
      loading.value = false
    })

    const selectedPartner = ref('Choose a partner')

    const leadsList = computed(() => {
      return store.getters.getterLeadsList
    })

    const partnersList = computed(() => {
      return store.getters.getterPartnersList
    })

    const dateFormat = (date: string) => {
      return moment(date).format('MMM DD, YYYY');
    }

    const deletePartner = (id: string) => {
      console.log('deletePartner ID - ', id)

      if ( id === 'Choose a partner' ) return

      Swal.fire({
        title: "Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {

          loader.value?.setAttribute("data-kt-indicator", "on");
          loading.value = true

          //  Dummy timeout
          setTimeout(() => {
            store.dispatch(Actions.DELETE_PARTNER, id)
                .then(() => {
                  store.dispatch(Actions.GET_PARTNERS_LIST)
                  .then(() => {
                    selectedPartner.value = 'Choose a partner'
                    loader.value?.removeAttribute("data-kt-indicator");
                    isSearched.value = false
                    loading.value = false
                  })

                })
          }, 1000)
        }
      })
      .catch(() => {
        Swal.fire({
          text: 'Error',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try Again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      })
    }

    const getPartner = (id: string) => {
      console.log('id - ', id)
      loader.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true

      //  Dummy timeout
      setTimeout(() => {
        store.dispatch(Actions.GET_LEADS_LIST, id)
            .then(() => {
              loader.value?.removeAttribute("data-kt-indicator");
              isSearched.value = true
              loading.value = false
            })
      }, 1000)
    }

    return {
      leadsList,
      partnersList,
      selectedPartner,
      checked,
      loader,
      loading,
      isSearched,
      dateFormat,
      getPartner,
      deletePartner,
      t,
    };
  },
});
