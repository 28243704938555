<template>
  <LeadsTable v-if="currentRouteName === 'leads'"/>
</template>

<script>
import LeadsTable from '@/views/dashboard/leads/Table.vue'
import {setCurrentPageBreadcrumbs} from "../../../core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default {
  name: "Leads",
  components: {
    LeadsTable
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  created() {
    console.log('leads created')
    store.dispatch(Actions.GET_PARTNERS_LIST)
  },
  setup() {
    setCurrentPageBreadcrumbs("Lead", []);
  }
}
</script>

<style scoped>

</style>